var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "TASK_LINK_" + _vm.id },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("task_predecessor.title_edit"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  !_vm.readOnly
                    ? _c(
                        "b-button",
                        {
                          staticStyle: { "margin-right": "auto" },
                          attrs: {
                            disabled: _vm.actionProcessing || !_vm.isDeletable,
                            size: "sm",
                            variant: "secondary",
                          },
                          on: { click: _vm.linkDelete },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                      )
                    : _vm._e(),
                  !_vm.readOnly
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.actionProcessing,
                            size: "sm",
                            variant: "success",
                          },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.$t("error.attention_required")) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("task_predecessor.field.predecessor"),
                "label-for": "task-link-predecessor-name",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "task-link-predecessor-name",
                  type: "text",
                  readonly: "",
                },
                model: {
                  value: _vm.link.pName,
                  callback: function ($$v) {
                    _vm.$set(_vm.link, "pName", $$v)
                  },
                  expression: "link.pName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("task_predecessor.field.task"),
                "label-for": "task-link-task-name",
              },
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "task-link-task-name",
                  type: "text",
                  readonly: "",
                },
                model: {
                  value: _vm.link.tName,
                  callback: function ($$v) {
                    _vm.$set(_vm.link, "tName", $$v)
                  },
                  expression: "link.tName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("task_predecessor.field.type"),
                "label-for": "task-link-type",
              },
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "task-link-type",
                  options: _vm.optionTaskLinkType,
                  "text-field": "label",
                  "value-field": "key",
                  "data-vv-as": _vm.$t("task_predecessor.field.type"),
                  "data-vv-name": "link.type",
                  "data-vv-delay": "500",
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.link.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.link, "type", $$v)
                  },
                  expression: "link.type",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c(
                    "label",
                    { staticClass: "mr-2", attrs: { for: "task-link-tag" } },
                    [_vm._v(_vm._s(_vm.$t("task_predecessor.field.lag")))]
                  ),
                  _c("font-awesome-icon", {
                    staticClass: "mb-2",
                    style: {
                      color: "var(--form-control-placeholder)",
                      fontSize: "0.9em",
                    },
                    attrs: {
                      id: "predecessor-lag",
                      icon: ["far", "circle-question"],
                    },
                  }),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "predecessor-lag",
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("task_predecessor.field.lag_hint")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("b-form-input", {
                attrs: {
                  id: "task-link-tag",
                  type: "number",
                  min: "0",
                  step: "1",
                  "data-vv-as": _vm.$t("task_predecessor.field.lag"),
                  "data-vv-name": "link.lag",
                  "data-vv-delay": "500",
                  readonly: _vm.readOnly,
                },
                on: { blur: _vm.lagFormat },
                model: {
                  value: _vm.link.lag,
                  callback: function ($$v) {
                    _vm.$set(_vm.link, "lag", $$v)
                  },
                  expression: "link.lag",
                },
              }),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showLagError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.errors.first("link.lag")) + " "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }